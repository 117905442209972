<template>
  <div>
    <div class="header_common_box">
        <v-header :params="{ title, leftIcon: true}"></v-header>
    </div>
    <div class="info">
      <div class="head">
        <div class="img"><img :src="userinfo.imgurl" /></div>
        <div class="name"><p>{{userinfo.username}}</p></div>
        <div class="fans"><p>关注 1<span>|</span>粉丝 888</p></div>
        <div class="button">
            <div class="followed" v-if="attention" @click="unfollow"><van-icon name="success" />已关注</div>
            <div class="unfollow" v-else @click="follow"><van-icon name="plus" />加关注</div>
        </div>
      </div>  
      <div class="body">
        <van-tabs v-model="active">
            <van-tab title="TA的发帖">
                <div>共888帖子</div>
                <div class="centerinfo">
                    <van-index-bar :index-list="indexList" highlight-color="red">
                        <div class="list" v-for="(itemone, index) in postList" :key="index">
                            <van-index-anchor index="index"><span>第{{itemone.periodText}}期</span><span style="text-align:center;color:red;margin-left: 2.0rem;">{{itemone.lotteryContent}}</span></van-index-anchor>
                            <hr style="width: 90%;opacity: 0.1;" />
                                <div class="head_bar">
                                    <van-image class="photo" round fit="cover" :src="userinfo.imgurl" />
                                    <span class="name">{{userinfo.username}} <span v-if="itemone.wonLotterySts===1" style="color:#fff;background-color:red;font-size:0.4rem;">中</span></span>
                                    <div class="icon_box">
                                    <div class="icon">
                                        <van-icon name="warn-o" size="20" color="#999" />
                                    </div>
                                    <div class="icon collect" @click="goCollect(itemone)">
                                        <van-icon name="star-o" size="20" color="#999" />
                                    </div>
                                    </div>
                                </div>
                                <p>第{{itemone.periodText}}期<span class="postTime">{{itemone.postTime | setDateMMdd}}</span><span v-if="itemone.roi>0" class="rateReturn">回报率:{{itemone.roi}}%</span></p>
                                <div class="contents ">
                                    <!-- 方案 -->
                                    <div >
                                    <div v-for="(ite,inde) in itemone.forumDetailsModels" :key="inde">
                                        <!-- <div v-html="ite.contents"></div> -->
                                        <p :class="ite.wonLotterySts===1?'red':'black'">[{{ite.categoryName}}]</p>
                                        <div v-if="ite.categoryName==='杀头'||ite.categoryName==='杀百'||ite.categoryName==='杀十'||ite.categoryName==='杀尾'">
                                        <p v-if="ite.textIndexPoint1!==''">
                                            千位：
                                            <span v-for="(item,index) in ite.textIndexPoint1" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint1Major!==''">
                                                主攻
                                                <span v-for="(item,index) in ite.textIndexPoint1Major" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                                                <span v-if="ite.textIndexPoint1Focus!==''">
                                                重点
                                                <span v-for="(item,index) in ite.textIndexPoint1Focus" :key="index" :class="item!==itemone.qian&&itemone.qian!==''?'red':'black'">{{item}}</span> 
                                                </span>
                                            </span>
                                        </p>
                                        <p v-if="ite.textIndexPoint2!==''">
                                            百位：
                                            <span v-for="(item,index) in ite.textIndexPoint2" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint2Major!==''">
                                                主攻
                                                <span v-for="(item,index) in ite.textIndexPoint2Major" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                                                <span v-if="ite.textIndexPoint2Focus!==''">
                                                重点
                                                <span v-for="(item,index) in ite.textIndexPoint2Focus" :key="index" :class="item!==itemone.bai&&itemone.bai!==''?'red':'black'">{{item}}</span> 
                                                </span>
                                            </span>
                                        </p>
                                        <p v-if="ite.textIndexPoint3!==''">
                                            十位：
                                            <span v-for="(item,index) in ite.textIndexPoint3" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint3Major!==''">
                                                主攻
                                                <span v-for="(item,index) in ite.textIndexPoint3Major" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                                                <span v-if="ite.textIndexPoint3Focus!==''">
                                                重点
                                                <span v-for="(item,index) in ite.textIndexPoint3Focus" :key="index" :class="item!==itemone.shi&&itemone.shi!==''?'red':'black'">{{item}}</span> 
                                                </span>
                                            </span>
                                        </p>
                                        <p v-if="ite.textIndexPoint4!==''">
                                            个位：
                                            <span v-for="(item,index) in ite.textIndexPoint4" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint4Major!==''">
                                                主攻
                                                <span v-for="(item,index) in ite.textIndexPoint4Major" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                                <span v-if="ite.textIndexPoint4Focus!==''">
                                                重点
                                                <span v-for="(item,index) in ite.textIndexPoint4Focus" :key="index" :class="item!==itemone.ge&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                                </span>
                                            </span>
                                        </p>
                                        </div>
                                        <div v-else-if="ite.categoryName==='死数'">
                                        <p v-if="ite.textIndexPointGeneral!==''">
                                            
                                            <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPointGeneralMajor!==''">
                                                主攻
                                                <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                                <span v-if="ite.textIndexPointGeneralFocus!==''">
                                                重点
                                                <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item!==itemone.qian&&item!==itemone.bai&&item!==itemone.shi&&item!==itemone.ge&&itemone.qian!==''&&itemone.bai!==''&&itemone.shi!==''&&itemone.ge!==''?'red':'black'">{{item}}</span> 
                                                </span>
                                            </span>
                                        </p>
                                        </div>
                                        <div v-else-if="ite.categoryName==='稳码'">
                                        <p v-if="ite.textIndexPointGeneral!==''">
                                            
                                            <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPointGeneralMajor!==''">
                                                主攻
                                                <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                                                <span v-if="ite.textIndexPointGeneralFocus!==''">
                                                重点
                                                <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===itemone.qian||item===itemone.bai||item===itemone.shi||item===itemone.ge?'red':'black'">{{item}}</span> 
                                                </span>
                                            </span>
                                        </p>
                                        </div>
                                        <div v-else-if="ite.categoryName==='头尾合'">
                                        <p v-if="ite.textIndexPointGeneral!==''">
                                            
                                            <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPointGeneralMajor!==''">
                                                主攻
                                                <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                                                <span v-if="ite.textIndexPointGeneralFocus!==''">
                                                重点
                                                <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===itemone.qiangesum?'red':'black'">{{item}}</span> 
                                                </span>
                                            </span>
                                        </p>
                                        </div>
                                        <div v-else-if="ite.categoryName==='中肚合'">
                                        <p v-if="ite.textIndexPointGeneral!==''">
                                            
                                            <span v-for="(item,index) in ite.textIndexPointGeneral" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPointGeneralMajor!==''">
                                                主攻
                                                <span v-for="(item,index) in ite.textIndexPointGeneralMajor" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                                                <span v-if="ite.textIndexPointGeneralFocus!==''">
                                                重点
                                                <span v-for="(item,index) in ite.textIndexPointGeneralFocus" :key="index" :class="item===itemone.baishisum?'red':'black'">{{item}}</span> 
                                                </span>
                                            </span>
                                        </p>
                                        </div>
                                        <div v-else-if="ite.categoryName==='二字现'">
                                        <p v-if="ite.textIndexPointGeneral!==''">
                                            
                                            <span v-for="(item,index) in ite.textIndexPointGeneral.split(',')" :key="index" :class="itemone.winning_number_twoshow.includes(item)?'red':'black'">{{item}} </span> 
                                        </p>
                                        </div>
                                        <div v-else-if="ite.categoryName==='三字现'">
                                        <p style="word-break:break-word;" v-if="ite.textIndexPointGeneral!==''">
                                            
                                            <span v-for="(item,index) in ite.textIndexPointGeneral.split(',')" :key="index" :class="itemone.winning_number_threeshow.includes(item)?'red':'black'">{{item}} </span> 
                                            
                                        </p>
                                        </div>
                                        <div v-else>
                                        <p v-if="(ite.textIndexPoint1!==''&&ite.textIndexPoint1!==undefined)">
                                            千位：
                                            <span v-for="(item,index) in ite.textIndexPoint1" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint1Major!==''">
                                                主攻
                                                <span v-for="(item,index) in ite.textIndexPoint1Major" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                                                <span v-if="ite.textIndexPoint1Focus!==''">
                                                重点
                                                <span v-for="(item,index) in ite.textIndexPoint1Focus" :key="index" :class="item===itemone.qian?'red':'black'">{{item}}</span> 
                                                </span>
                                            </span>
                                        </p>
                                        <p v-if="(ite.textIndexPoint2!==''&&ite.textIndexPoint2!==undefined)">
                                            百位：
                                            <span v-for="(item,index) in ite.textIndexPoint2" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint2Major!==''">
                                                主攻
                                                <span v-for="(item,index) in ite.textIndexPoint2Major" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                                                <span v-if="ite.textIndexPoint2Focus!==''">
                                                重点
                                                <span v-for="(item,index) in ite.textIndexPoint2Focus" :key="index" :class="item===itemone.bai?'red':'black'">{{item}}</span> 
                                                </span>
                                            </span>
                                        </p>
                                        <p v-if="(ite.textIndexPoint3!==''&&ite.textIndexPoint3!==undefined)">
                                            十位：
                                            <span v-for="(item,index) in ite.textIndexPoint3" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint3Major!==''">
                                                主攻
                                                <span v-for="(item,index) in ite.textIndexPoint3Major" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                                                <span v-if="ite.textIndexPoint3Focus!==''">
                                                重点
                                                <span v-for="(item,index) in ite.textIndexPoint3Focus" :key="index" :class="item===itemone.shi?'red':'black'">{{item}}</span> 
                                                </span>
                                            </span>
                                        </p>
                                        <p v-if="(ite.textIndexPoint4!==''&&ite.textIndexPoint4!==undefined)">
                                            个位：
                                            <span v-for="(item,index) in ite.textIndexPoint4" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                                            <span v-if="ite.textIndexPoint4Major!==''">
                                                主攻
                                                <span v-for="(item,index) in ite.textIndexPoint4Major" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                                                <span v-if="ite.textIndexPoint4Focus!==''">
                                                重点
                                                <span v-for="(item,index) in ite.textIndexPoint4Focus" :key="index" :class="item===itemone.ge?'red':'black'">{{item}}</span> 
                                                </span>
                                            </span>
                                        </p>
                                        </div>
                                        
                                        
                                    
                                    
                                    </div>
                                    </div>
                                
                                    <!-- 照片 -->
                                    <!-- <div class="photo_box">
                                    <van-image class="photo" fit="cover" v-for="(row, i) in itemone.imagesUrlArray" :key="i + 1000000" :src="row" @click="goImagePreview(item.imagesUrlArray, i )" />
                                    </div> -->
                                </div>
                                <div class="foot_bar">
                                    <div :class="itemone.upCount == '1' ? 'icon Selected' : 'icon'" @click="goThumbsSts(itemone, index)">
                                    <van-icon name="good-job-o" size="24" color="#999" />
                                    {{itemone.upCount || 0}}
                                    </div>
                                    <div class="icon" @click="goShare()" >
                                    <van-icon name="share-o" size="24" color="#999" />
                                    {{itemone.shareCount || 0}}
                                    </div>
                                    <div class="icon">
                                    <van-icon name="comment-o" size="24" color="#999" />
                                    {{itemone.commentCount || 0}}
                                    </div>
                                </div>
                            
                        </div>
                    

                    </van-index-bar>
                </div>
            </van-tab>
            <van-tab title="TA的评论">TA的所有评论的帖子，包括不是他的</van-tab>
            <van-tab title="TA的成绩">
                <van-tabs v-model="active2">
                    <van-tab title="个人能力">
                        <van-grid :column-num="3">
                            <van-grid-item    text="位置" />
                            <van-grid-item   text="成绩" />
                            <van-grid-item   text="回报率" />
                        </van-grid>
                        <div v-for="(item,index) in mypower" :key="index">
                            <van-grid :column-num="3">
                                <van-grid-item   :text="item.username" />
                                <van-grid-item    ><p style="color:rgba(43, 43, 239, 0.682);">近10中{{item.hitnum}}</p><p>最高连中{{item.continuous}}</p></van-grid-item>
                                <van-grid-item    :text="item.roi+'%'" />
                            </van-grid>
                        </div>
                        
                    </van-tab>
                    <van-tab title="周榜">
                        <div v-if="(weekrankinginfo.length===0)"><p class="notinfo">无名人榜记录（暂无入周榜记录）</p></div>
                        <div v-else class="items" v-for="(item,index) in weekrankinginfo" :key="index" @click="weekranking(item)">
                            <div class="one"><van-icon name="label-o" size="1rem"/></div>
                            <div class="two"><p>{{item.period}}周榜</p><p>{{item.startDate}}~{{item.endDate}}</p></div>
                            <div class="three"><p>NO.{{item.ranking}} ></p></div>
                        </div>
                    </van-tab>
                    <van-tab title="月榜">
                        <div v-if="(monthrankinginfo.length===0)"><p  class="notinfo">无名人榜记录（暂无入月榜记录）</p></div>
                        <div v-else class="items" v-for="(item,index) in monthrankinginfo" :key="index" @click="monthranking(item)">
                            <div class="one"><van-icon name="label-o" size="1rem"/></div>
                            <div class="two"><p>{{item.period}}月榜</p><p>{{item.startDate}}~{{item.endDate}}</p></div>
                            <div class="three"><p>NO.{{item.ranking}} ></p></div>
                        </div>
                    </van-tab>
                </van-tabs>
            </van-tab>
        </van-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import Moment from 'moment';
import wxShare from "@/assets/js/util/wxShare.js";


export default {
    data(){
        return{
            title:'个人信息',
            userinfo:JSON.parse(this.$route.query.userinfo),
            attention:false,
            active:0,
            active2:0,
            indexList:[],
            postList:[
                    {periodText:'2815',postTime:'2022-11-10',roi:'-100',lotteryContent:'4796726',qian:'4',ge:'6',wonLotterySts:0,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:0,textIndexPoint1:'23467',textIndexPoint1Major:'236',textIndexPoint1Focus:'23',textIndexPoint4:'01458',textIndexPoint4Major:'158',textIndexPoint4Focus:'15'}]},
                    {periodText:'2816',postTime:'2022-11-10',roi:'100',lotteryContent:'88130711',qian:'8',ge:'3',wonLotterySts:1,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:1,textIndexPoint1:'13578',textIndexPoint1Major:'137',textIndexPoint1Focus:'37',textIndexPoint4:'03469',textIndexPoint4Major:'036',textIndexPoint4Focus:'36'}]},
                    {periodText:'2817',postTime:'2022-11-10',roi:'-100',lotteryContent:'4796726',qian:'4',ge:'6',wonLotterySts:0,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:0,textIndexPoint1:'23467',textIndexPoint1Major:'236',textIndexPoint1Focus:'23',textIndexPoint4:'01458',textIndexPoint4Major:'158',textIndexPoint4Focus:'15'}]},
                    {periodText:'2818',postTime:'2022-11-10',roi:'-100',lotteryContent:'4796726',qian:'4',ge:'6',wonLotterySts:0,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:0,textIndexPoint1:'23467',textIndexPoint1Major:'236',textIndexPoint1Focus:'23',textIndexPoint4:'01458',textIndexPoint4Major:'158',textIndexPoint4Focus:'15'}]},
                    {periodText:'2819',postTime:'2022-11-10',roi:'-100',lotteryContent:'4796726',qian:'4',ge:'6',wonLotterySts:0,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:0,textIndexPoint1:'23467',textIndexPoint1Major:'236',textIndexPoint1Focus:'23',textIndexPoint4:'01458',textIndexPoint4Major:'158',textIndexPoint4Focus:'15'}]},
                    {periodText:'2820',postTime:'2022-11-10',roi:'100',lotteryContent:'88130711',qian:'8',ge:'3',wonLotterySts:1,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:1,textIndexPoint1:'13578',textIndexPoint1Major:'137',textIndexPoint1Focus:'37',textIndexPoint4:'03469',textIndexPoint4Major:'036',textIndexPoint4Focus:'36'}]},
                    {periodText:'2821',postTime:'2022-11-10',roi:'100',lotteryContent:'88130711',qian:'8',ge:'3',wonLotterySts:1,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:1,textIndexPoint1:'13578',textIndexPoint1Major:'137',textIndexPoint1Focus:'37',textIndexPoint4:'03469',textIndexPoint4Major:'036',textIndexPoint4Focus:'36'}]},
                    {periodText:'2822',postTime:'2022-11-10',roi:'100',lotteryContent:'88130711',qian:'8',ge:'3',wonLotterySts:1,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:1,textIndexPoint1:'13578',textIndexPoint1Major:'137',textIndexPoint1Focus:'37',textIndexPoint4:'03469',textIndexPoint4Major:'036',textIndexPoint4Focus:'36'}]},
                    {periodText:'2823',postTime:'2022-11-10',roi:'100',lotteryContent:'88130711',qian:'8',ge:'3',wonLotterySts:1,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:1,textIndexPoint1:'13578',textIndexPoint1Major:'137',textIndexPoint1Focus:'37',textIndexPoint4:'03469',textIndexPoint4Major:'036',textIndexPoint4Focus:'36'}]},
                    {periodText:'2824',postTime:'2022-11-10',roi:'100',lotteryContent:'88130711',qian:'8',ge:'3',wonLotterySts:1,forumDetailsModels:[{categoryName:'头尾',wonLotterySts:1,textIndexPoint1:'13578',textIndexPoint1Major:'137',textIndexPoint1Focus:'37',textIndexPoint4:'03469',textIndexPoint4Major:'036',textIndexPoint4Focus:'36'}]},
                  
                ],
            mypower:[{imgurl:'https://img2.baidu.com/it/u=430334000,1441640885&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500',username:'头尾',roi:'384.85',continuous:'4',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F27%2F20210627172214_c6be1.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=b24f8b175d2e32992490512e375cead0',username:'中肚',roi:'344.85',continuous:'6',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',username:'芝麻',roi:'284.85',continuous:'3',hitnum:'7'},{imgurl:'https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fblog%2F202106%2F14%2F20210614060633_74f83.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1673686124&t=2df4009513a3949ea88695360e700e77',username:'定十',roi:'184.85',continuous:'3',hitnum:'7'}],
            monthrankinginfo:[
                {period:'221205',startDate:'2022-12-05',endDate:'2022-12-12',ranking:'1'},
                {period:'221203',startDate:'2022-12-05',endDate:'2022-12-12',ranking:'2'},
                {period:'221201',startDate:'2022-12-05',endDate:'2022-12-12',ranking:'3'},
                {period:'221122',startDate:'2022-12-05',endDate:'2022-12-12',ranking:'10'},
                {period:'221123',startDate:'2022-12-05',endDate:'2022-12-12',ranking:'8'},
                {period:'221123',startDate:'2022-12-05',endDate:'2022-12-12',ranking:'6'},
                {period:'221123',startDate:'2022-12-05',endDate:'2022-12-12',ranking:'1'},
            ],
            weekrankinginfo:[
                {period:'221205',startDate:'2022-12-05',endDate:'2022-12-12',ranking:'1'},
                {period:'221203',startDate:'2022-12-05',endDate:'2022-12-12',ranking:'2'},
                {period:'221201',startDate:'2022-12-05',endDate:'2022-12-12',ranking:'3'},
                {period:'221122',startDate:'2022-12-05',endDate:'2022-12-12',ranking:'9'},
                {period:'221123',startDate:'2022-12-05',endDate:'2022-12-12',ranking:'8'},
            ],
            mycomments:[
                {},
            ]

        }
    },
    components:{
        vHeader
    },
    filters: {
        setDateMMdd(vle) {
        if (vle) { return Moment(vle).format('MM月DD') }
        return '';
        }
    },
    methods:{
        //关注
        follow(){
            this.attention=true
        },
        //取消关注
        unfollow(){
            this.attention=false

        },
        // 收藏帖子（1）收藏（0）取消收藏
        async goCollect(item) {
        if (this.access_token) {
            const res = await this.$axios({
            method: "put",
            url: this.$API['API_FORUM_FAV_STATUS'] + '?forum_id=' + item.forumId + '&status=1',
            data: { forum_id: item.forumId, status: '1' },
            headers: { access_token: this.access_token }
            })
            if (res.data && res.data.statusCode === 200) {
            this.$toast('收藏成功');
            }
        } else {
            this.goLoginConfirm()
        }
        },
        // 点赞（1）点踩（2） 或 取消（3）
        async goThumbsSts(item, index) {
        if (this.access_token) {
            const res = await this.$axios({
            method: "put",
            url: this.$API['API_FORUM_THUMBS_STS'] + '?forum_id=' + item.forumId + '&status=1',
            data: { forum_id: item.forumId, status: '1' },
            headers: { access_token: this.access_token }
            })
            if (res.data && res.data.statusCode === 200) {
            this.postList[index].upCount = '1'
            this.$toast('点赞成功');
            }
        } else {
            this.goLoginConfirm()
        }
        },
        goShare(){
        wxShare.goShare({
            shareTitle: '测试标题',
            shareText: '测试内容',
            shareImgUrl: '/logo.jpg',
            shareUrl: document.URL
        });
        },
        monthranking(n){
            console.log('某期的信息',n)
            this.$router.push({
                name:'ranking',
                query:{
                    title:'月榜'
                }
            })
        },
        weekranking(n){
            console.log('某期的信息',n)
            this.$router.push({
                name:'ranking',
                query:{
                    title:'周榜'
                }
            })
        }
    },
}
</script>

<style lang="less" scoped>
*{
    padding: 0;
    margin:0;
}
p{
    font-size: 0.38rem;
}
.red{
    color:red;
}
.black{
    color:black;
}
.info{
    position: fixed;
    top: 1.2rem;
    left: 0rem;
    bottom: 0rem;
    right: 0rem;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    font-size: 15px;
}
.head{
    .img{
        text-align: center;
        margin: 0.2rem;
        img{
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
        }
    }
    .name{
        text-align: center;
        margin: 0.2rem;
        p{
            font-size: 0.7rem;
            color:rgba(43, 43, 239, 0.682);
        }
    }
    .fans{
        text-align: center;
        margin: 0.2rem;
        span{
            margin: 0rem 1rem;
            opacity: 0.5;
        }
    }
    .button{
        position:absolute;
        top:2rem;
        right: 0.5rem;
        width: 2rem;
        height: 1rem;
        font-size: 0.38rem;
        text-align: center;
        .followed{
            background-color: white;
            border-radius: 0.1rem;

        }
        .unfollow{
            color: white;
            background-color: red;
            border-radius: 0.1rem;


        }
    }
    
}
.body{
    
    .centerinfo{
        
        .list {
        background-color: white;
        margin: 4px;
        padding: 10px 10px 0;
        p {
            margin: 0;
            font-size: 15px;
        }
        .head_bar {
            display: flex;
            align-items: center;
            .photo {
            width: 40px;
            height: 40px;
            }
            .name {
            font-size: 18px;
            margin-left: 10px;
            flex: 1;
            }
            .icon_box {
            display: flex;
            .icon {
                background-color: #e5e5e5;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                margin-left: 12px;
            }
            .icon:active {
                color: white;
                background-color: #fb3e44;
            }
            }
        }
        .postTime {
            color: #999;
            margin-left: 10px;
        }
        .rateReturn{
            // margin-left: 3.8rem;
            font-size: 0.3rem;
            margin-right: 0.3rem;
            float: right;
        }
        .contents {
            padding-bottom: 10px;
            .photo_box {
            margin-top: 12px;
            .photo {
                width: 72px;
                height: 72px;
                margin: 0 8px 2px 0;
            }
            }
        }
        .foot_bar {
            display: flex;
            justify-content: space-around;
            .icon {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            font-size: 15px;
            color: #999;
            }
            .Selected {
            color: #3abaf7;
            .van-icon {
                color: #3abaf7 !important;
            }
            }
        }
        }
    }
    .notinfo{
        text-align: center;
        opacity: 0.5;
    }
}
.items{
    padding: 0.1rem;
    margin: 0.1rem 0;
    background-color: white;
    div{
        display: inline-block;
    }
    .one{
        width: 20%;
    }
    .two{
        width:60%;
    }
    .three{
        width:20%
    }
}

</style>